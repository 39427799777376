import { getAndDeleteCookie } from './cookies'

export const CSRF_TOKEN_KEY = 'bridge-platform-runtime.csrf_token'
export const ASTRAL_CSRF_COOKIE_NAME = '__bridge_csrf'
export const ASTRAL_CSRF_TOKEN_KEY = 'bridge-platform-runtime.astral_csrf_token'
export const ASTRAL_REFRESH_COOKIE_NAME = '__bridge_refresh'
export const ASTRAL_REFRESH_TOKEN_KEY =
  'bridge-platform-runtime.astral_refresh_token'

const bailBecause = (reason) => {
  throw new Error(`Unable to create session.\n\nSource error: ${reason}`)
}

export class AstralSessionAdapter {
  constructor({ localStorage, reissueHandler }) {
    this.localStorage = localStorage
    this.reissueHandler = reissueHandler
  }

  canRefresh() {
    return !!this.localStorage.getItem(ASTRAL_REFRESH_TOKEN_KEY)
  }

  getCsrfToken() {
    return this.localStorage.getItem(ASTRAL_CSRF_TOKEN_KEY)
  }

  getRefreshParameters() {
    return {
      refresh_token: this.localStorage.getItem(ASTRAL_REFRESH_TOKEN_KEY),
    }
  }

  refresh() {
    const csrfToken = getAndDeleteCookie(ASTRAL_CSRF_COOKIE_NAME)

    if (csrfToken) {
      this.localStorage.setItem(ASTRAL_CSRF_TOKEN_KEY, csrfToken)
      this.localStorage.setItem(CSRF_TOKEN_KEY, csrfToken)
    }
  }

  save() {
    const saveCsrfToken = () => {
      const csrfToken = getAndDeleteCookie(ASTRAL_CSRF_COOKIE_NAME)
      if (csrfToken) {
        this.localStorage.setItem(ASTRAL_CSRF_TOKEN_KEY, csrfToken)
        this.localStorage.setItem(CSRF_TOKEN_KEY, csrfToken)
        // eslint-disable-next-line no-prototype-builtins
      } else if (!this.localStorage.hasOwnProperty(ASTRAL_CSRF_TOKEN_KEY)) {
        bailBecause('Authmonger csrf token (csrf_token) missing.')
      }
    }

    const refreshToken = getAndDeleteCookie(ASTRAL_REFRESH_COOKIE_NAME)
    if (refreshToken) {
      // Clean up presumably stale learn-legacy token
      this.localStorage.removeItem('bridgeRefreshToken')
      this.localStorage.setItem(ASTRAL_REFRESH_TOKEN_KEY, refreshToken)
      this.localStorage.setItem('showAnnouncement', true)
      // eslint-disable-next-line no-prototype-builtins
    } else if (!this.localStorage.hasOwnProperty(ASTRAL_REFRESH_TOKEN_KEY)) {
      return this.reissueHandler().then(() => saveCsrfToken())
    }

    saveCsrfToken()
  }
}
